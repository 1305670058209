/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/**********************************************
 * Bones
 */

@import 'node_modules/@bones/core/assets/css/bones.scss';
@import 'node_modules/@bones/edit/assets/css/bones-edit.scss';

bones-app-launcher-icon .la
{
    color: var(--ion-color-medium) !important;
}

bones-app-launcher-icon .la.active
{
    color: var(--ion-color-light) !important;
}

/**********************************************
 * Common styles
 */

// Prevent both back button and hamburger menu being visible
.can-go-back
{
    ion-menu-button
    {
        display: none;
    }
}

.kps-pre-line
{
    white-space: pre-line;
}

// A card with padding does not need extra padding on list items unless they are in a group
ion-card.ion-padding ion-item
{
    --padding-start: 0;
}
ion-card.ion-padding ion-item-group ion-item
{
    --padding-start: var(--ion-padding);
}

ion-item-sliding ion-item ion-label
{
    min-height: 3em;
}
