// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// --------------------------------------------------------------------------

:root
{
    // https://colorzilla.com/gradient-editor/#800080+0,000080+100
    --med-gradient-purple-blue: linear-gradient(to bottom, #800080 0%,#000080 100%);
}

// --------------------------------------------------------------------------

:root {
    /* Set the background of the entire app */
    // --ion-background-color: var(--med-gradient-red-gold);

    /* Set the font family of the entire app */
    --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}

ion-content
{
    --ion-background-color: var(--med-gradient-purple-blue);
}
ion-header ion-toolbar, ion-footer ion-toolbar
{
    --ion-toolbar-color: var(--ion-color-light);
    --ion-toolbar-background: var(--ion-color-secondary);
}
ion-list, ion-item, ion-searchbar
{
    --color: var(--ion-color-dark);
    --ion-background-color: var(--ion-color-light);
}
ion-searchbar input
{
    background-color: var(--ion-color-light) !important; // iOS
}
ion-card
{
    --background: var(--ion-color-light);
    margin: 10px; // needed for iOS as it has different default margin
}
ion-card-title
{
    --color: var(--ion-color-secondary);
}
ion-list-header, ion-list-title
{
    --color: var(--ion-color-secondary);
}
ion-item-divider
{
    --background: var(--ion-color-secondary);
    --color: var(--ion-color-light);
}

// --------------------------------------------------------------------------

/**
 * Ionic CSS Variables
 * Generated by: https://ionicframework.com/docs/theming/color-generator
 **/
:root {
  --ion-color-primary: #000080;
  --ion-color-primary-rgb: 0,0,128;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #000071;
  --ion-color-primary-tint: #1a1a8d;

  --ion-color-secondary: #8a0303;
  --ion-color-secondary-rgb: 138,3,3;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #790303;
  --ion-color-secondary-tint: #961c1c;

  --ion-color-tertiary: #800080;
  --ion-color-tertiary-rgb: 128,0,128;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #710071;
  --ion-color-tertiary-tint: #8d1a8d;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
